import { Helmet } from 'react-helmet'
import SectionSpacer from '../../common/shared/layout/SectionSpacer'
import Page from '../../common/shared/layout/Page'
import IntroSlogen from './sections/IntroSlogen'
import HomeSlogen from './sections/HomeSlogen'
import IntroGallery from './sections/IntroGallery'
import Coding from './sections/Coding'
import ContactUs from './sections/ContactUs'
import Footer from './sections/Footer'
import Welcome from './sections/Welcome'
import EducationalModel from './sections/EducationalModel'
import EducationalApproach from './sections/EducationalApproach'
import Container from '../../common/shared/layout/Container'
import Outdoor from './sections/Outdoor'
import About from './sections/About'
import Vision from './sections/Vision'
import Sustainability from './sections/Sustainability2'
import GreenAI from './sections/GreenAI'
import InventionsGallery from '../il-home/sections/InventionsGallery'
import FocusSection from '../../common/shared/layout/FocusSection'
import InterApproch from './sections/InterApproch'
import ProblemBased from './sections/ProblemBased'
import Handson from './sections/Handson'
import Slogen from '../../common/shared/layout/SlogenSection'
import SingleDisplay from '../../common/shared/layout/SingleDisplay'
import Spacer from '../../common/shared/layout/Spacer'
import { spacing } from '../../common/style'
import AboutProjects from './sections/AboutProjects'
import ML from './sections/ML'
import Team from '../il-home/sections/Team'
import { useViewport } from '../../useViewport'

const Home = () => {
    const { device } = useViewport()
    return (
        <Page eng={true}>
            <Helmet>
                <title>Comets: Innovative Educational AI Programs</title>
                <meta name="description" content="A groundbreaking educational platform focused on AI and sustainability."/>
            </Helmet>
            <Welcome/>
                {
                    (device === 'mobile' || device == 'tablet') && (
                        <Container id="general">
                            <About customHeader={{}}/>
                            <SectionSpacer/>
                            <IntroGallery/>
                            <SectionSpacer/>
                        </Container>
                    )
                }
                {
                    (device === 'desktop') && (
                        <Container  center width={12}>
                            <SectionSpacer id="general"/>
                            <IntroSlogen/>
                            <SectionSpacer/>
                            <IntroGallery/>
                            <SectionSpacer/>
                            <About/>
                            <SectionSpacer/>
                        </Container>
                    )
                } 
            <HomeSlogen/>
            <SectionSpacer id="featuers"/>
            <Vision/>
            <SectionSpacer/>
            <EducationalModel/>
            <SectionSpacer/>
            <EducationalApproach/>
            <SectionSpacer/>
            <Slogen>
                Through Comets, students engage in cutting-edge AI projects, developing practical skills for the 21st Century.
            </Slogen>
            <SectionSpacer/>
            <SingleDisplay eng
                image={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719817174/elirannatan_photo_of_a_human_spotted_in_the_middle_of_the_ocean_55efd23a-4591-4714-ba1d-d4f99f985496_sfstzr.png'} 
                imageMobile={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1718866650/Group_45_wn00xw.png'}
                caption={'Discovering vessels amidst the storm: Apollo students unveil StormSight, a cutting-edge deep learning image processing model, adept at pinpointing small vessels in aerial footage. The model is tested on augmented data from the Stable Diffusion Model. '}/>
            <Spacer height={spacing.spacing20}/>
            <AboutProjects/>
            <SectionSpacer id="content"/>
            <FocusSection eng header={{
                topic: 'Curriculum Overview',
                title: <span>
                    <span style={{fontWeight:'200'}}>
                        A 
                    </span>
                    { " "}
                    <span style={{fontWeight:'300'}}>
                    Next-Generation 
                    </span>
                    { " "}
                    <span style={{fontWeight:'200'}}>
                    Curriculum
                    </span>
                </span>,
                desc: 'Comets curriculum is designed to equip students with the skills needed to transform complex scientific questions and engineering problems into innovative Machine Learning solutions and effectively architect robust solutions tailored to these challenges.'
            }}/>
            <InterApproch/>
            <SectionSpacer/>
            <ML/>
            <Spacer height={spacing.spacing20}/>
            <SingleDisplay 
                image={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719687720/image_7_stxo0g.png'} 
                imageMobile={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719866824/image_7_stxo0g_c_crop_ar_1_1_dip4eg.png'}
                width={9}/>
            <SectionSpacer/>
            <ProblemBased/>
            <SectionSpacer/>
            <Coding/>
            <Spacer height={spacing.spacing20}/>
            <SingleDisplay 
                image={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1720007106/Group_16_1_mqurji.png'} 
                imageMobile={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1720007204/Group_16_1_mqurji_c_crop_ar_1_1_ypeyx1.png'}
                width={9}/>  
            <SectionSpacer id="sustain"/>
            <FocusSection eng header={{
                topic: 'Sustainability',
                title: <span style={{fontWeight:'200'}}>
                    <span style={{fontWeight:'300'}}>
                    Reconnecting
                    </span>
                    {" "}
                    with the 
                    Environment
                </span>,
                desc: "At Comets, we integrate artificial intelligence studies with real-world contexts, recognizing AI's intricate relationship with its environment. This holistic approach propels us beyond the confines of computer labs, engaging students in hands-on research across diverse ecosystems—from deserts to oceans to fields. By bridging the gap between technology and nature, we foster a deeper understanding of AI's role in addressing global challenges and promoting sustainability."
            }}/>
            <Sustainability/>
            <SectionSpacer/>
            <Outdoor/>
            <SectionSpacer/>
            <GreenAI/>
            <Spacer height={spacing.spacing20}/>
            <SingleDisplay 
                image={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719686733/elirannatan_Satellite_photography_of_wind_turbines_farm_--ar_16_b590efb9-3253-4b22-ae77-104502ea0645_afyr24.png'} 
                imageMobile={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719912298/elirannatan_Satellite_photography_of_wind_turbines_farm_--ar_16_b590efb9-3253-4b22-ae77-104502ea0645_afyr24_c_crop_ar_1_1_lwph9i.png'}
                width={9}/> 
            <SectionSpacer id="contact"/>
            <ContactUs/>
            <Footer/>
        </Page>  
    )
}

export default Home